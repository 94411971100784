var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"d-flex flex-wrap align-items-center"},_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index,staticClass:"px-18"},[_c('b-link',{class:`px-24 py-10${
        _vm.path.split('/')[1] == item.header.toLowerCase() ? ' active' : ''
      }`},[_c('span',[_vm._v(_vm._s(item.header))]),_c('i',{staticClass:"ri-arrow-down-s-line"})]),_c('ul',{staticClass:"dropdown-menu"},_vm._l((item.children),function(item,index){return _c('li',{key:index},[(item.children)?_c('b-link',{class:`dropdown-item${
            _vm.path.split('/')[2] == item.id ? ' active' : ''
          }`},[_c('span',[_c('i',{class:`iconly-${item.icon}`}),_c('span',[_vm._v(_vm._s(item.title))])]),_c('i',{staticClass:"ri-arrow-right-s-line"})]):_c('b-link',{class:`${_vm.path == item.navLink ? 'active' : ''}`,attrs:{"to":item.navLink}},[_c('span',[_c('i',{class:`iconly-${item.icon}`}),_c('span',[_vm._v(_vm._s(item.title))])])]),(item.children)?_c('ul',{staticClass:"dropdown-menu"},_vm._l((item.children),function(item,index){return _c('li',{key:index,staticClass:"dropend"},[(item.children)?_c('b-link',{class:`dropdown-item${
                _vm.path.split('/')[3] == item.id ? ' active' : ''
              }`},[_c('span',[_vm._v(_vm._s(item.title))]),_c('i',{staticClass:"ri-arrow-right-s-line"})]):(item.target)?_c('b-link',{class:`${_vm.path == item.navLink ? ' active' : ''}`,attrs:{"href":item.navLink,"target":item.target}},[_c('span',[_vm._v(_vm._s(item.title))])]):_c('b-link',{class:`${_vm.path == item.navLink ? ' active' : ''}`,attrs:{"to":item.navLink}},[_c('span',[_vm._v(_vm._s(item.title))])]),(item.children)?_c('ul',{staticClass:"dropdown-menu"},_vm._l((item.children),function(item,index){return _c('li',{key:index},[_c('b-link',{class:`${_vm.path == item.navLink ? ' active' : ''}`,attrs:{"to":item.navLink}},[_c('span',[_vm._v(_vm._s(item.title))])])],1)}),0):_vm._e()],1)}),0):_vm._e()],1)}),0)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }